@font-face {
  font-family: 'brandon_grotesquethin';
  src: url('../fonts/brandon_thin-webfont.woff2') format('woff2'),
  url('../fonts/brandon_thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'brandon_grotesqueregular';
  src: url('../fonts/brandon_reg-webfont.woff2') format('woff2'),
  url('../fonts/brandon_reg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'brandon_grotesqueRgIt';
  src: url('../fonts/brandon_reg_it-webfont.woff2') format('woff2'),
  url('../fonts/brandon_reg_it-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'brandon_grotesquemedium';
  src: url('../fonts/brandon_med-webfont.woff2') format('woff2'),
  url('../fonts/brandon_med-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'brandon_grotesquelight';
  src: url('../fonts/brandon_light-webfont.woff2') format('woff2'),
  url('../fonts/brandon_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'brandon_grotesquebold';
  src: url('../fonts/brandon_bld-webfont.woff2') format('woff2'),
  url('../fonts/brandon_bld-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
/*# sourceMappingURL=maps/async-fonts.css.map */